import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { updateCustomerAccProvider } from 'src/customer/actions';
import './styles.scss';

const CustomerAccountRedirectUriForm = ({
  companyID,
  closeCb,
  confirmCb,
  accountID,
  redirectUrl,
}) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [redirectURL, setRedirectURL] = useState(redirectUrl || '');
  const [redirectURLError, setRedirectURLError] = useState('');

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new user value', { value: val });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const editCustomerAccountRedirectUri = () => {
    const data = { redirect_uri: redirectURL };
    updateCustomerAccProvider(companyID, accountID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
        closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit customer company details', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleSubmit = () => {
    const isValid = validateValue(redirectURL, setRedirectURLError);
    if (loading || !isValid) { return false; }
    setLoading(true);

    editCustomerAccountRedirectUri();
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      title={__('Edit redirect URI')}
      size="sm"
    >
      <div className="CustomerAccountRedirectUriForm">
        <div className="redirect-row">
          <Label text={__('Redirect URI')} inputId="redirect-url" />
          <TextInput
            id="redirect-url"
            value={redirectURL}
            error={redirectURLError}
            handleChange={(val) => {
              setDirty(true);
              setRedirectURL(val);
              debouncedValidateRequiredValue(val).then(err => setRedirectURLError(err));
            }}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

CustomerAccountRedirectUriForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  redirectUrl: PropTypes.string,
};

CustomerAccountRedirectUriForm.defaultProps = {
  redirectUrl: '',
};

export default CustomerAccountRedirectUriForm;
